<template>
  <div>
    <v-container >
      <v-row class="elevation-1">
        <v-col cols="6" lg="12">
          <DepartmentSelection
            v-model="criteria.departments"
          />
        </v-col>
        <v-col cols="6" lg="12">
          <FySelect id="odUtilApportFySelect"
            class="od-util-apport-fy-select"
            :fyRange="odStatsFY"
            v-model="criteria.fiscalYear"
          />
        </v-col>
        <v-col cols="6" lg="6">
          <ClarionDateControl id="odUtilApportFromDate"
            classes="tmc-util-apport-fromDt"
            v-model="criteria.fromDate"
            :isRequired="true"
            label="From Date"
          />
        </v-col>
        <v-col cols="6" lg="6">
          <ClarionDateControl id="odUtilApportToDate"
            classes="tmc-util-apport-toDt"
            v-model="criteria.toDate"
            :isRequired="true"
            label="To Date"
          />
        </v-col>
        <v-col cols="6" lg="3">
          <v-text-field class="od-util-appor-interest-amount-text"
            id="tmc-util-apport-intAmt"
            v-model.number="criteria.interestAmount"
            label="Interest Amount"
            type="number"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="elevation-1">
        <v-col cols="6" lg="3">
          <v-btn class="od-util-apport-viewreport-btn"
            id="tmc-util-apport-view"
            rounded
            color="primary"
            @click="viewReport"
            :disabled="!isCorrectInterestAmount"
          >
            <v-icon>{{icons.mdiNote}}</v-icon>
            <span class="ml-4">{{caption}}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  mdiNote,
} from '@mdi/js';
import { mapGetters, mapState, mapActions } from 'vuex';
import DepartmentSelection from '@/components/common/DepartmentSelection.vue';
import { toShortDate, toFiscalYear } from '../../../util/shared/tmc-global';
import { isDate } from '../../../util/shared/vue-global';
import ClarionDateControl from '../../../components/common/ClarionDateControl.vue';
import FySelect from '../../../components/common/FySelect.vue';

export default {
  name: 'OfficialDepositoryInterestApportionment',
  components: {
    DepartmentSelection,
    ClarionDateControl,
    FySelect,
  },
  data: () => ({
    icons: {
      mdiNote,
    },
    criteria: {
      departments: [],
      fromDate: undefined,
      toDate: undefined,
      interestAmount: 0.0,
    },
    caption: 'View Report',
  }),
  created() {
    // populate defaults
    this.criteria.fromDate = toShortDate(this.todaysDate);
    this.criteria.toDate = toShortDate(this.todaysDate);
    this.criteria.fiscalYear = toFiscalYear(new Date(this.todaysDate));
    this.loadFiscalYears(false);
  },
  computed: {
    ...mapGetters([
      'todaysDate',
    ]),
    ...mapState('OD', [
      'odStatsFY',
    ]),
    isCorrectInterestAmount() {
      return this.criteria.interestAmount && (this.criteria.interestAmount > 0);
    },
  },
  methods: {
    ...mapActions('OD', [
      'loadFiscalYears',
    ]),
    viewReport() {
      if (isDate(this.criteria.fromDate) && isDate(this.criteria.toDate)) {
        const queryCriteria = {
          fromDate: this.criteria.fromDate,
          toDate: this.criteria.toDate,
          departments: this.criteria.departments,
          interestAmount: this.criteria.interestAmount,
          fiscalYear: this.criteria.fiscalYear,
        };
        this.$store.dispatch('OD/fetchInterestApportionment', queryCriteria);
        this.$router.push({ name: 'Official Depository - Interest Apportionment Report' });
      }
    },
  },
};
</script>
