<template>
  <div class="DepartmentSelection">
    <v-col>
      <v-select id="tmcByDepartmentSelect"
        v-model="selectedDepartments"
        :items="departmentList"
        item-text="caption"
        item-value="id"
        label="By department"
        multiple
      >
        <template v-slot:selection="{ item, index }"
          >
          <v-chip v-if="index === 0">
            <span>{{ item.caption }}</span>
          </v-chip>
          <span
            v-if="index === 1"
            class="grey--text text-caption"
          >
            (+{{ selectedDepartments.length - 1 }} others)
          </span>
        </template>
        <template v-slot:prepend-item
          >
          <v-list-item
            ripple
            @click="toggle"
          >
            <v-list-item-action>
              <v-icon :color="selectedDepartments.length > 0 ? 'indigo darken-4' : ''">
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Select All
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-select>
    </v-col>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {
  mdiSelectAll,
  mdiCloseBox,
  mdiCheckboxBlankOutline,
  mdiMinusBox,
} from '@mdi/js';

export default {
  name: 'DepartmentSelection',
  components: {},
  data: () => ({
    icons: {
      mdiSelectAll,
      mdiCloseBox,
      mdiCheckboxBlankOutline,
      mdiMinusBox,
    },
  }),
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    isLoadDeptExplicitly: {
      type: Boolean,
      default: () => true,
    },
  },
  created() {
    this.loadAndSelectDepartments();
  },
  computed: {
    ...mapState({
      departmentList: (state) => state.OD.departments || [],
    }),
    selectedDepartments: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isAllDeptsSelected() {
      return this.selectedDepartments.length === this.departmentList.length;
    },
    isSomeDeptsSelected() {
      return this.selectedDepartments.length > 0 && !this.isAllDeptsSelected;
    },
    icon() {
      if (this.isAllDeptsSelected) return this.icons.mdiCloseBox;
      if (this.isSomeDeptsSelected) return this.icons.mdiMinusBox;
      return this.icons.mdiCheckboxBlankOutline;
    },
  },
  methods: {
    ...mapActions({
      loadDepartments: 'OD/loadDepartments',
    }),
    async loadAndSelectDepartments() {
      if (this.isLoadDeptExplicitly) {
        await this.loadDepartments();
      }
      this.selectAllDepts();
    },
    selectAllDepts() {
      this.selectedDepartments = [...this.departmentList.map((d) => d.id)];
    },
    toggle() {
      this.$nextTick(() => {
        if (this.isAllDeptsSelected) {
          this.selectedDepartments = [];
        } else {
          this.selectAllDepts();
        }
      });
    },
  },
};
</script>
